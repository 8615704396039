<template>
    <div>
      {{ motif }}
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      computed: {
          ...mapGetters({
              motifs: 'sanitaire/motifConsultations'
          }),
          motif(){
            let m = JSON.parse(this.object.subject)
            if(m.other) return m.libelle
            let consultaiton = this.motifs.find(item => item.uid === m.libelle)
            if(consultaiton) return consultaiton.libelle
            return '-'
          }
      }
  
  }
  </script>